import React, { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import NavigationButton from './NavigationButton';
import bgIntro from '../assets/bgintro.svg';
import introductionIconLight from '../assets/introductionOutlineLight.svg';
import introductionIconDark from '../assets/introductionOutlineDark.svg';
import { ThemeContext } from '../App';

const MainContent = () => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <div style={{ 
      flexGrow: 1, 
      padding: '60px 120px 60px 120px', 
      boxSizing: 'border-box', 
      overflowY: 'auto', 
      fontFamily: 'Arial, sans-serif', 
      backgroundImage: `url(${bgIntro})`, 
      backgroundRepeat: 'no-repeat', 
      backgroundPosition: 'right', 
      backgroundSize: '20%', 
      backgroundAttachment: 'fixed'
    }}>
      <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '24px', fontSize: '32px', lineHeight: '1.2', display: 'flex', alignItems: 'center' }}>
        <img src={isDarkMode ? introductionIconDark : introductionIconLight} alt="Introduction Icon" style={{ width: '40px', height: '40px', marginRight: '8px' }} />
        Introduction
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
        <strong>Welcome to the Software Evaluation Tool.</strong><br />
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
        <strong>What is this tool?</strong><br />
        This tool is a companion to the philosophy and content on Smudge.com. The tool is designed to be used by anyone working on a software project of any size (either individually or as part of a team) to help with decision-making and provoke new lines of questioning.
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
        <strong>Why did we create it?</strong><br />
        In software development, there is constant tension between business requirements, technical considerations, and user experience. Balancing these areas is crucial to getting a successful outcome. We use this tool at Smudge to sense-check our own decisions, and we thought other people may benefit from using it, too.
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
        <strong>How do I use it?</strong><br />
        1. Read through the questions in the tool.<br />
        2. Apply them to your own project(s) and make notes on your observations.<br />
        3. We encourage you to complete all three sections; however, you may want to deep-dive into particular sections if they are less familiar to you.<br />
        4. You can complete the sections in any order.<br />
        5. Once you complete the sections, tap “Results” in the menu bar to see the result.
      </Typography>
      <Typography variant="body1" style={{ fontSize: '18px', lineHeight: '1.6' }}>
        <strong>What happens to my data?</strong><br />
        The observations you write in the tool will be stored locally in your browser. Your data is never transferred to the server or the cloud. You can clear your answers (or start a new project) at any time using the Clear My Answers button in the Result page.
      </Typography>
      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton direction="previous" text="Previous" link="/" description="Introduction" />
        <NavigationButton direction="next" text="Next" link="/business-considerations" description="Business Considerations - Overview" />
      </Box>
    </div>
  );
};

export default MainContent;
