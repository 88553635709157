import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import QuestionSection from '../components/QuestionSection';
import NavigationButton from '../components/NavigationButton';
import bgred from '../assets/bgred.svg';
import smudgeDotRedIcon from '../assets/smudgeDotRed.svg';
import AnswersContext from '../context/AnswersContext';

const UXFlexibility = () => {
  const { answers, saveAnswer } = useContext(AnswersContext);

  const questions1 = [
    { id: "UXFlexibility1", text: "Do you support accessibility features (e.g. variable font size, speech-to-text) where appropriate?" },
    { id: "UXFlexibility2", text: "Do you provide multiple input methods (e.g. shortcuts, gestures, voice recognition)?" },
    { id: "UXFlexibility3", text: "Have you considered a variety of constraints (e.g. colour-blindness, one-handed use, bright sunlight)?" }
  ];

  const questions2 = [
    { id: "UXFlexibility4", text: "Does your user flow support a variety of contextual entry points? Different users will be solving for different needs (e.g. “I want something to eat right now” vs “What is the best restaurant in the city?”)" },
    { id: "UXFlexibility5", text: "At any point in the flow, is the user forced to stop what they're doing or go backwards in order to continue or complete a task?" }
  ];

  const questions3 = [
    { id: "UXFlexibility6", text: "How forgiving is your interface? Could a user accidentally perform a destructive action?" },
    { id: "UXFlexibility7", text: "When a user makes an error, do you provide clear options to keep moving? (e.g. “Sorry, that hotel is full, but here are three similar options nearby”)." }
  ];

  const handleInputChange = (id, value) => {
    saveAnswer(id, value);
  };

  return (
    <div
      style={{
        flexGrow: 1,
        padding: '60px 120px 60px 120px', 
        boxSizing: 'border-box',
        overflowY: 'auto',
        fontFamily: 'Arial, sans-serif',
        backgroundImage: `url(${bgred})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: '20%', 
        backgroundAttachment: 'fixed'
      }}
    >
      <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '24px', fontSize: '32px', lineHeight: '1.2' }}>
        <img src={smudgeDotRedIcon} alt="Smudge Dot Red Icon" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
        Flexibility
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
        Empower the user to work in a way that suits them.
      </Typography>

      <QuestionSection 
        title="Accessibility" 
        description="Design for everyone in your audience: users may have varying levels of expertise and/or cognitive and/or physical ability. Anticipate and support usage in different environments."
        questions={questions1}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection 
        title="Context" 
        description="Users should be able to initiate interactions based on a wide variety of contextual intentions. Support non-linear workflows."
        questions={questions2}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection  
        title="Fallibility" 
        description="Forgive human error. Users should be able to experiment, change their minds and make mistakes without breaking anything."
        questions={questions3}
        answers={answers}
        onInputChange={handleInputChange}
      />

      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton direction="previous" text="Previous" link="/user-experience-communication" description="User Experience - Communication" />
        <NavigationButton direction="next" text="Next" link="/result" description="Result" />
      </Box>
    </div>
  );
};

export default UXFlexibility;
