import React, { useState, useEffect, createContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/SideBar';
import MainContent from './components/MainContent';
import ProjectEvaluation from './pages/ProjectEvaluation';
import TechnicalConsiderations from './pages/TechnicalConsiderations';
import BusinessConsiderations from './pages/BusinessConsiderations';
import BusinessCapability from './pages/BusinessCapability';
import BusinessProcessesAndTools from './pages/BusinessProcessesAndTools';
import TechnicalCapability from './pages/TechnicalCapability';
import TechnicalSustainability from './pages/TechnicalSustainability';
import UXConsistency from './pages/UXConsistency';
import UXFlexibility from './pages/UXFlexibility';
import UXCommunication from './pages/UXCommunication';
import UserExperience from './pages/UserExperience';
import Result from './pages/Result';
import { AnswersProvider } from './context/AnswersContext';

export const ThemeContext = createContext();

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Check initial theme
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(darkThemeMq.matches);

    // Listen for theme changes
    const handleThemeChange = (e) => {
      setIsDarkMode(e.matches);
    };

    darkThemeMq.addEventListener('change', handleThemeChange);

    // Clean up listener on component unmount
    return () => {
      darkThemeMq.removeEventListener('change', handleThemeChange);
    };
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
    if (!isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  };  

  const appStyle = {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    marginTop: '64px',
    backgroundColor: isDarkMode ? '#2E2E2E' : '#f4f3f1',
    color: isDarkMode ? '#FFFFFF' : '#000000',
    overflow: 'hidden',
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      <AnswersProvider>
        <Header />
        <div style={appStyle}>
          <div style={{ width: '315px', flexShrink: 0, overflowY: 'auto', backgroundColor: isDarkMode ? '#3B3B3B' : '#f4f3f1' }}>
            <Sidebar />
          </div>
          <div style={{ width: '1px', backgroundColor: isDarkMode ? '#4D4D4D' : '#d3d3d3', height: '80%' }}></div> {/* Divider */}
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/project-evaluation" element={<ProjectEvaluation />} />
            <Route path="/technical-considerations" element={<TechnicalConsiderations />} />
            <Route path="/business-considerations" element={<BusinessConsiderations />} />
            <Route path="/business-capability" element={<BusinessCapability />} />
            <Route path="/business-processes-and-tools" element={<BusinessProcessesAndTools />} />
            <Route path="/technical-capability" element={<TechnicalCapability />} />
            <Route path="/technical-sustainability" element={<TechnicalSustainability />} />
            <Route path="/user-experience" element={<UserExperience />} />
            <Route path="/user-experience-consistency" element={<UXConsistency />} />
            <Route path="/user-experience-communication" element={<UXCommunication />} />
            <Route path="/user-experience-flexibility" element={<UXFlexibility />} />
            <Route path="/result" element={<Result />} />
          </Routes>
        </div>
      </AnswersProvider>
    </ThemeContext.Provider>
  );
}

export default App;