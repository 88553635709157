import React, { useState, useEffect, useContext } from 'react';
import { List, ListItem, ListItemText, ListItemButton, Divider, TextField, Typography, Box, InputAdornment, IconButton, Collapse, ListItemIcon } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useLocation } from 'react-router-dom';
import introductionIcon from '../assets/introductionOutlineLight.svg';
import introductionIconDark from '../assets/introductionOutlineDark.svg';
import smudgeDotRedIcon from '../assets/smudgeDotRed.svg';
import smudgeDotYellowIcon from '../assets/smudgeDotYellow.svg';
import smudgeDotBlueIcon from '../assets/smudgeDotBlue.svg';
import smudgeDotsIcon from '../assets/smudgeDots.svg';
import { ThemeContext } from '../App';

const Sidebar = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const location = useLocation();
  const [projectName, setProjectName] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openBusiness, setOpenBusiness] = useState(false);
  const [openTechnical, setOpenTechnical] = useState(false);
  const [openUserExperience, setOpenUserExperience] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case '/':
        setSelectedIndex(0);
        break;
      case '/business-considerations':
        setSelectedIndex(1);
        setOpenBusiness(true);
        break;
      case '/project-evaluation':
        setSelectedIndex(2);
        setOpenBusiness(true);
        break;
      case '/business-capability':
        setSelectedIndex(3);
        setOpenBusiness(true);
        break;
      case '/business-processes-and-tools':
        setSelectedIndex(4);
        setOpenBusiness(true);
        break;
      case '/technical-considerations':
        setSelectedIndex(5);
        setOpenTechnical(true);
        break;
      case '/technical-capability':
        setSelectedIndex(6);
        setOpenTechnical(true);
        break;
      case '/technical-sustainability':
        setSelectedIndex(7);
        setOpenTechnical(true);
        break;
      case '/user-experience':
        setSelectedIndex(8);
        setOpenUserExperience(true);
        break;
      case '/user-experience-consistency':
        setSelectedIndex(9);
        setOpenUserExperience(true);
        break;
      case '/user-experience-communication':
        setSelectedIndex(10);
        setOpenUserExperience(true);
        break;
      case '/user-experience-flexibility':
        setSelectedIndex(11);
        setOpenUserExperience(true);
        break;
      case '/result':
        setSelectedIndex(12);
        break;
      default:
        setSelectedIndex(0);
    }
  }, [location]);

  const handleInputChange = (event) => {
    setProjectName(event.target.value);
    setIsSaved(false);
  };

  const handleSave = () => {
    if (projectName) {
      setIsSaved(true);
    }
  };

  const handleEdit = () => {
    setIsSaved(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleBusinessClick = () => {
    setOpenBusiness(!openBusiness);
  };

  const handleTechnicalClick = () => {
    setOpenTechnical(!openTechnical);
  };

  const handleUserExperienceClick = () => {
    setOpenUserExperience(!openUserExperience);
  };

  const listItemButtonStyle = {
    borderRadius: '15px',
    padding: '8px 16px',
    transition: 'box-shadow 0.3s',
  };

  const hoverStyle = {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
  };

  return (
    <div style={{ height: 'calc(100vh - 64px)', width: '300px', backgroundColor: isDarkMode ? '#3B3B3B' : '#f4f3f1', display: 'flex', flexDirection: 'column', flexShrink: 0 }}>
      <Box padding="20px" flexGrow={1} boxSizing="border-box">
        <Typography variant="body" style={{ fontSize: '16px', margin: '8px' }}>Project Name</Typography>
        {!isSaved ? (
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Project Name"
            value={projectName}
            onChange={handleInputChange}
            style={{ marginTop: '16px', marginBottom: '16px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={handleSave}
                    disabled={!projectName}
                  >
                    <SaveIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: { borderRadius: '15px', backgroundColor: isDarkMode ? '#4D4D4D' : '#FFFFFF', color: isDarkMode ? '#FFFFFF' : '#000000' }
            }}
          />
        ) : (
          <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: '24px', marginBottom: '40px', marginLeft: '16px' }}>
            <Typography variant="h7" style={{ fontWeight: 'bold', color: isDarkMode ? '#FFFFFF' : '#000000' }}>{projectName}</Typography>
            <IconButton onClick={handleEdit} style={{ color: isDarkMode ? '#FFFFFF' : '#000000' }}>
              <EditIcon />
            </IconButton>
          </Box>
        )}
        <Divider style={{ marginTop: '16px', marginBottom: '16px', backgroundColor: isDarkMode ? '#4D4D4D' : '#d3d3d3' }} />
        <List>
          <ListItem key="Introduction" style={{ padding: 0 }}>
            <ListItemButton
              component={Link} to="/"
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
              style={{
                ...listItemButtonStyle,
                backgroundColor: selectedIndex === 0 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                color: isDarkMode ? '#FFFFFF' : '#000000',
              }}
              onMouseEnter={(e) => !selectedIndex && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
              onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
            >
              <ListItemIcon style={{ minWidth: '45px' }}>
                <img src={isDarkMode ? introductionIconDark : introductionIcon} alt="Introduction Icon" style={{ width: '24px', height: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="Introduction" />
            </ListItemButton>
          </ListItem>
          <ListItemButton onClick={handleBusinessClick}>
            <ListItemIcon style={{ minWidth: '45px' }}>
              <img src={smudgeDotYellowIcon} alt="Smudge Dot Yellow Icon" style={{ width: '24px', height: '24px' }} />
            </ListItemIcon>
            <ListItemText primary="Business Considerations" />
            {openBusiness ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openBusiness} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem key="Overview" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/business-considerations"
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 1 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 1 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </ListItem>
              <ListItem key="Project Evaluation" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/project-evaluation"
                  selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 2 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 2 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Project Evaluation" />
                </ListItemButton>
              </ListItem>
              <ListItem key="Capability" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/business-capability"
                  selected={selectedIndex === 3}
                  onClick={(event) => handleListItemClick(event, 3)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 3 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 3 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Capability" />
                </ListItemButton>
              </ListItem>
              <ListItem key="Processes And Tools" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/business-processes-and-tools"
                  selected={selectedIndex === 4}
                  onClick={(event) => handleListItemClick(event, 4)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 4 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 4 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Processes and Tools" />
                </ListItemButton>
              </ListItem>
              {/* Add more nested items here */}
            </List>
          </Collapse>
          <ListItemButton onClick={handleTechnicalClick}>
            <ListItemIcon style={{ minWidth: '45px' }}>
              <img src={smudgeDotBlueIcon} alt="Smudge Dot Blue Icon" style={{ width: '24px', height: '24px' }} />
            </ListItemIcon>
            <ListItemText primary="Technical Considerations" />
            {openTechnical ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openTechnical} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem key="Overview" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/technical-considerations"
                  selected={selectedIndex === 5}
                  onClick={(event) => handleListItemClick(event, 5)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 5 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 5 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </ListItem>
              <ListItem key="Capability" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/technical-capability"
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 6 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 6 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Capability" />
                </ListItemButton>
              </ListItem>
              <ListItem key="Sustainability" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/technical-sustainability"
                  selected={selectedIndex === 7}
                  onClick={(event) => handleListItemClick(event, 7)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 7 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 7 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Sustainability" />
                </ListItemButton>
              </ListItem>
              {/* Add more nested items here */}
            </List>
          </Collapse>
          <ListItemButton onClick={handleUserExperienceClick}>
            <ListItemIcon style={{ minWidth: '45px' }}>
              <img src={smudgeDotRedIcon} alt="Smudge Dot Red Icon" style={{ width: '24px', height: '24px' }} />
            </ListItemIcon>
            <ListItemText primary="User Experience" />
            {openUserExperience ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openUserExperience} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem key="Overview" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/user-experience"
                  selected={selectedIndex === 8}
                  onClick={(event) => handleListItemClick(event, 8)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 8 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 8 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </ListItem>
              <ListItem key="Consistency" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/user-experience-consistency"
                  selected={selectedIndex === 9}
                  onClick={(event) => handleListItemClick(event, 9)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 9 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 9 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Consistency" />
                </ListItemButton>
              </ListItem>
              <ListItem key="Communication" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/user-experience-communication"
                  selected={selectedIndex === 10}
                  onClick={(event) => handleListItemClick(event, 10)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 10 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 10 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Communication" />
                </ListItemButton>
              </ListItem>
              <ListItem key="Flexibility" style={{ paddingLeft: 32 }}>
                <ListItemButton
                  component={Link} to="/user-experience-flexibility"
                  selected={selectedIndex === 11}
                  onClick={(event) => handleListItemClick(event, 11)}
                  style={{
                    ...listItemButtonStyle,
                    backgroundColor: selectedIndex === 11 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                  }}
                  onMouseEnter={(e) => selectedIndex !== 11 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
                  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
                >
                  <ListItemText primary="Flexibility" />
                </ListItemButton>
              </ListItem>
              {/* Add more nested items here */}
            </List>
          </Collapse>
          <ListItem key="Results" style={{ padding: 0 }}>
            <ListItemButton
              component={Link} to="/result"
              selected={selectedIndex === 12}
              onClick={(event) => handleListItemClick(event, 12)}
              style={{
                ...listItemButtonStyle,
                backgroundColor: selectedIndex === 12 ? (isDarkMode ? '#616161' : '#EAEFFF') : 'transparent',
                color: isDarkMode ? '#FFFFFF' : '#000000',
              }}
              onMouseEnter={(e) => selectedIndex !== 12 && (e.currentTarget.style.boxShadow = hoverStyle.boxShadow)}
              onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
            >
              <ListItemIcon style={{ minWidth: '45px' }}>
                <img src={smudgeDotsIcon} alt="Smudge Dots Icon" style={{ width: '24px', height: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="Results" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </div>
  );
};

export default Sidebar;
