import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import QuestionSection from '../components/QuestionSection';
import NavigationButton from '../components/NavigationButton';
import bgyellow from '../assets/bgyellow.svg';
import smudgeDotYellowIcon from '../assets/smudgeDotYellow.svg';
import AnswersContext from '../context/AnswersContext';

const ProjectEvaluation = () => {
  const { answers, saveAnswer } = useContext(AnswersContext);

  const questions1 = [
    { id: "ProjectEvaluation1", text: "Are your own purpose and values sufficiently clear that you're able to apply them as criteria when evaluating a new project?" },
    { id: "ProjectEvaluation2", text: "When considering a new project, do you assess the purpose and values of your customer(s) or stakeholder(s) before making a decision?" },
    { id: "ProjectEvaluation3", text: "Under what circumstances would you decline a project (e.g. on ethical grounds) even if it could be financially lucrative?" }
  ];

  const questions2 = [
    { id: "ProjectEvaluation4", text: "In your view, does the project have genuine strategic importance? Does it solve a problem that is worth solving?" },
    { id: "ProjectEvaluation5", text: "Is the project part of a broader business or go-to-market strategy? If so, how well do you understand the wider strategy and how does this project fit within it?" },
    { id: "ProjectEvaluation6", text: "Is the project a good fit with your own strategic goals? Is this the type of work you want to be doing?" }
  ];

  const questions3 = [
    { id: "ProjectEvaluation7", text: "Are you and your customer(s) or stakeholder(s) fully cognisant of the estimated project costs? Can all parties afford to embark on the project?" },
    { id: "ProjectEvaluation8", text: "Who is paying for the project, and when? Has a fee structure been agreed? Do you need a contract?" },
    { id: "ProjectEvaluation9", text: "Do you or your customer(s) intend to monetize the product or solution? If so, what is the business model? Is the ongoing success of the project dependent on generating revenue?" }
  ];

  const handleInputChange = (id, value) => {
    saveAnswer(id, value);
  };

  return (
    <div
      style={{
        flexGrow: 1,
        padding: '60px 120px 60px 120px', 
        boxSizing: 'border-box',
        overflowY: 'auto',
        fontFamily: 'Arial, sans-serif',
        backgroundImage: `url(${bgyellow})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: '20%', 
        backgroundAttachment: 'fixed'
      }}
    >
      <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '24px', fontSize: '32px', lineHeight: '1.2' }}>
        <img src={smudgeDotYellowIcon} alt="Smudge Dot Yellow Icon" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
         Project Evaluation
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
        When evaluating a new product, solution or feature, what are your criteria?
      </Typography>

      <QuestionSection title="Purpose and Values" questions={questions1} answers={answers} onInputChange={handleInputChange} />
      <QuestionSection title="Strategy" questions={questions2} answers={answers} onInputChange={handleInputChange} />
      <QuestionSection title="Financial Viability" questions={questions3} answers={answers} onInputChange={handleInputChange} />

      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton direction="previous" text="Previous" link="/business-considerations" description="Business Considerations - Overview" />
        <NavigationButton direction="next" text="Next" link="/business-capability" description="Business Considerations - Capability" />
      </Box>
    </div>
  );
};

export default ProjectEvaluation;
