import React from 'react';
import { Typography, Box } from '@mui/material';
import NavigationButton from '../components/NavigationButton';
import bgyellow from '../assets/bgyellow.svg';
import smudgeDotYellowIcon from '../assets/smudgeDotYellow.svg';

const BusinessConsiderations = () => (
  <div style={{ 
    flexGrow: 1, 
    padding: '60px 120px 60px 120px', 
    boxSizing: 'border-box', 
    overflowY: 'auto', 
    fontFamily: 'Arial, sans-serif', 
    backgroundImage: `url(${bgyellow})`, 
    backgroundRepeat: 'no-repeat', 
    backgroundPosition: 'right', 
    backgroundSize: '20%', 
    backgroundAttachment: 'fixed'
  }}>
    <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '24px', fontSize: '32px', lineHeight: '1.2' }}>
      <img src={smudgeDotYellowIcon} alt="Smudge Dot Red Icon" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
      Business Considerations
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      <strong>Taking care of business.</strong><br />
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      This section of the tool is designed to help you evaluate a project, product or solution against a set of commercial considerations.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      In some cases, the project might be commissioned by a customer or stakeholder; however, many of these considerations are also relevant if you're working on a personal project.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      This section is divided into three interrelated areas: Project Evaluation, Capability and Resourcing, and Processes and Tools.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      While the questions and considerations are not linear, there are some gates along the way. For example, if you decide not to take on a project because it's not a good strategic fit, you won't need to worry about whether you have the people, skills or processes to tackle it.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      Get started by clicking Next (below) or using the navigation links in the sidebar.
    </Typography>
    <Box display="flex" justifyContent="space-between" marginTop="24px">
      <NavigationButton direction="previous" text="Previous" link="/" description="Introduction" />
      <NavigationButton direction="next" text="Next" link="/project-evaluation" description="Business Considerations - Project Evaluation" />
    </Box>
  </div>
);

export default BusinessConsiderations;
