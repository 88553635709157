import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoLight } from '../assets/logoLight.svg';
import { ReactComponent as LogoDark } from '../assets/logo.svg';
import { ThemeContext } from '../App';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const Header = () => {
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);

  return (
    <AppBar position="fixed" style={{ backgroundColor: isDarkMode ? '#424242' : '#ffffff', color: isDarkMode ? '#FFFFFF' : '#000000', boxShadow: 'none', borderBottom: isDarkMode ? '1px solid #616161' : '1px solid #e0e0e0' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '0 16px' }}>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          {isDarkMode ? <LogoLight style={{ width: '160px', height: '30px', marginRight: '150px' }} /> : <LogoDark style={{ width: '160px', height: '30px', marginRight: '150px' }} />}
        </Link>
        <Typography variant="h7" style={{ fontWeight: 'bold' }}>Software Evaluation Tool</Typography>
        <div style={{ flexGrow: 1 }}></div>
        <IconButton onClick={toggleDarkMode} color="inherit">
          {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
