import React from 'react';
import { Typography, Box } from '@mui/material';
import NavigationButton from '../components/NavigationButton';
import bgred from '../assets/bgred.svg';
import smudgeDotRedIcon from '../assets/smudgeDotRed.svg';

const UserExperience = () => (
  <div style={{
    flexGrow: 1,
    padding: '60px 120px 60px 120px',
    boxSizing: 'border-box',
    overflowY: 'auto',
    fontFamily: 'Arial, sans-serif',
    backgroundImage: `url(${bgred})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: '20%',
    backgroundAttachment: 'fixed'
  }}>
    <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '24px', fontSize: '32px', lineHeight: '1.2' }}>
      <img src={smudgeDotRedIcon} alt="Smudge Dot Red Icon" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
      User Experience
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      <strong>What makes a great user experience?</strong><br />
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      To help answer that question, we've developed a set of usability heuristics that build on the past while reflecting the behaviours and expectations of modern users. Read more about the heuristics here.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      This section of the tool is designed to help you evaluate an existing interface(s) or wireframe(s) with those heuristics in mind.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      Remember, the best insights come from watching users interact with a solution. Use this tool to help categorise and validate your observations.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      This resource is designed with the following assumptions in mind:
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      You have identified a clear need for your solution.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      You know your audience, and have identified clear personas.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      This section is divided into three interlinking categories: Consistency, Communication and Flexibility.
    </Typography>
    <Typography variant="body1" style={{ marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
      Get started by clicking Next (below) or use the navigation links in the sidebar.
    </Typography>
    <Box display="flex" justifyContent="space-between" marginTop="24px">
      <NavigationButton direction="previous" text="Previous" link="/technical-sustainability" description="Technical Considerations - Sustainability" />
      <NavigationButton direction="next" text="Next" link="/user-experience-consistency" description="User Experience - Consistency" />
    </Box>
  </div>
);

export default UserExperience;
